import classNames from "classnames";
import { Link } from "gatsby";
import * as React from "react";
import { useEffect, useState } from "react";
import { ProgrammeField } from "../pages/programme";

interface ProgrammeCarouselProps {
  nodes: ProgrammeField[];
}
const ProgrammeCarousel: React.FC<ProgrammeCarouselProps> = ({ nodes }) => {
  const [current, setCurrent] = useState<ProgrammeField | undefined>();
  const [upcoming, setUpcoming] = useState<ProgrammeField | undefined>();
  const getColor = (type: string) => {
    let c = "text-red-500";
    switch (type) {
      case "Break":
        c = "text-yellow-500";
        break;
      // case "Q&A":
      //   c = "text-teal-200";
      //   break;
      case "Other":
        c = "text-zinc-200";
        break;
      default:
        break;
    }
    return c;
  };

  const updateProgramme = () => {
    console.log("updating carousel");
    const now = new Date();
    // now.setDate(8);
    // now.setMonth(4);
    // now.setHours(11);
    // now.setMinutes(12);
    now.setHours(now.getHours() + 2);
    const newCurrent = nodes.find(
      (node) =>
        new Date(node.data.Start_Time) < now &&
        new Date(node.data.End_Time) > now
    );
    const newUpcoming = nodes.find(
      (node) => new Date(node.data.Start_Time) > now
    );
    setCurrent(newCurrent);
    setUpcoming(newUpcoming);
  };

  React.useEffect(() => {
    updateProgramme();
    const timer = setInterval(updateProgramme, 30000);
    return () => clearInterval(timer);
  }, []);

  if (current) {
    console.log(current.data.Mentimeter_link);
    return (
      <div className="max-w-screen-lg mx-auto mt-5">
        <div className="rounded border-4 border-red-800 bg-zinc-800 py-4 px-5 text-center">
          <h3 className="text-xl md:text-2xl uppercase">Now</h3>
          <p className="text-zinc-400 md:text-lg">
            {new Date(current.data.Start_Time).toLocaleTimeString("se", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "GMT",
            })}{" "}
            -{" "}
            {new Date(current.data.End_Time).toLocaleTimeString("se", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "GMT",
            })}
          </p>
          <h4
            className={classNames([
              "text-2xl md:text-3xl",
              getColor(current.data.Type),
            ])}
          >
            {current.data.Name}
          </h4>
          {!!current.data.Faculty &&
            current.data.Faculty.map(({ data: speaker, recordId }) => (
              <Link to={"/faculty#" + recordId} key={recordId}>
                <p className="text-zinc-200 text-xl">{speaker.Name}</p>
                <p className="text-zinc-400">
                  {speaker.Institution}, {speaker.Country}
                </p>
              </Link>
            ))}
          {!!current.data.Comment && (
            <div className="mt-2">
              <p>{current.data.Comment}</p>
            </div>
          )}
          {!!current.data.Mentimeter_link && (
            <a
              href={current.data.Mentimeter_link}
              target="_blank"
              className="px-5 py-2 mt-3 inline-block bg-red-800 rounded hover:bg-red-900"
            >
              Join quiz
            </a>
          )}
          {!!current.data.Syllabus &&
            current.data.Syllabus.localFiles.map((syl) => (
              <a
                href={syl.publicURL}
                target="_blank"
                className="px-5 py-2 mt-3 inline-block bg-red-800 rounded hover:bg-red-900"
              >
                Lecture PDF
              </a>
            ))}
        </div>
        {upcoming && (
          <div className="bg-zinc-800 rounded-b py-4 px-5 text-center w-4/5 md:w-3/4 mx-auto">
            <h3 className="text-lg md:text-xl uppercase">Upcoming</h3>
            <p className="text-zinc-400">
              {new Date(upcoming.data.Start_Time).toLocaleTimeString("se", {
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "GMT",
              })}{" "}
              -{" "}
              {new Date(upcoming.data.End_Time).toLocaleTimeString("se", {
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "GMT",
              })}
            </p>
            <h4
              className={classNames([
                "text-xl md:text-2xl",
                getColor(upcoming.data.Type),
              ])}
            >
              {upcoming.data.Name}
            </h4>
            {!!upcoming.data.Faculty && (
              <div>
                {upcoming.data.Faculty.map(({ data: speaker, recordId }) => (
                  <Link to={"/faculty#" + recordId} key={recordId}>
                    <p className="text-zinc-200">{speaker.Name}</p>
                    <p className="text-zinc-400 text-sm">
                      {speaker.Institution}, {speaker.Country}
                    </p>
                  </Link>
                ))}
              </div>
            )}
            {!!upcoming.data.Comment && (
              <div className="mt-2">
                <p>{upcoming.data.Comment}</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
  return <div></div>;
};

export default ProgrammeCarousel;
