import { graphql, Link } from "gatsby";
import * as React from "react";
import Layout from "../layout/Layout";
import classNames from "classnames";
import ProgrammeCarousel from "../components/ProgrammeCarousel";

interface ProgrammeProps {
  data: {
    allAirtable: {
      group: {
        nodes: ProgrammeField[];
        fieldValue: string;
      }[];
      nodes: ProgrammeField[];
    };
  };
}

export interface ProgrammeField {
  data: {
    Name: string;
    Start_Time: string;
    End_Time: string;
    Comment?: string;
    Session_Number?: number[];
    Session_Title?: string[];
    Type: string;
    Faculty?: FacultyField[];
    Sessions?: SessionsField[];
    Syllabus?: SyllabusField;
    Mentimeter_link?: string;
  };
  recordId: string;
}

interface FacultyField {
  data: {
    Name: string;
    Country: string;
    Institution: string;
  };
  recordId: string;
}

interface SyllabusField {
  localFiles: {
    publicURL: string;
    id: string;
  }[];
}

interface SessionsField {
  data: {
    Number: number;
    Title: string;
    Faculty?: FacultyField[];
  };
}
const Programme: React.FC<ProgrammeProps> = ({
  data: {
    allAirtable: { group, nodes: carouselNodes },
  },
}) => {
  let currentSession: number | null = 0;

  const getColor = (type: string) => {
    let color = "text-red-500";

    switch (type) {
      case "Break":
        color = "text-yellow-500";
        break;
      // case "Q&A":
      //   color = "text-teal-200";
      //   break;
      case "Other":
        color = "text-emerald-500";
        break;
      default:
        break;
    }
    return color;
  };

  console.log(group);

  return (
    <Layout title="Programme">
      <h2 className="text-4xl text-center font-light uppercase">Programme</h2>
      <ProgrammeCarousel nodes={carouselNodes} />
      <div className="text-center">
        {group.map(({ fieldValue, nodes }) => (
          <div key={fieldValue}>
            <div className="bg-red-900 py-3 mt-5 rounded-t border-b border-zinc-500">
              <h3 className="text-2xl font-light">
                {new Date(fieldValue).toLocaleDateString("en", {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                })}
              </h3>
            </div>
            {nodes.map(({ data, recordId }, index) => {
              let showSession = false;
              if (
                !!data.Sessions &&
                data.Sessions[0].data.Number !== currentSession
              ) {
                // console.log(data.Session_Number[0], currentSession);
                showSession = true;
                currentSession = data.Sessions[0].data.Number;
              }

              return (
                <React.Fragment key={recordId}>
                  {showSession && data.Sessions && (
                    <div
                      className={classNames([
                        "py-3 border-red-600",
                        index === 0 ? "border-b" : "border-y mt-3",
                      ])}
                    >
                      <h4 className="text-xl text-red-500">
                        Session {data.Sessions[0].data.Number}
                      </h4>
                      <p>{data.Sessions[0].data.Title}</p>
                      {!!data.Sessions[0].data.Faculty &&
                        data.Sessions[0].data.Faculty.map((moderator) => (
                          <p key={moderator.recordId} className="text-zinc-400">
                            Moderator: {moderator.data.Name},{" "}
                            {moderator.data.Country}
                          </p>
                        ))}
                    </div>
                  )}
                  <div
                    className={classNames([
                      "py-2 bg-zinc-800 px-3",
                      index === 0 || showSession ? "rounded-b" : "rounded mt-2",
                    ])}
                    id={recordId}
                  >
                    <p className="text-zinc-400">
                      {new Date(data.Start_Time).toLocaleTimeString("fi", {
                        hour: "2-digit",
                        minute: "2-digit",
                        timeZone: "GMT",
                      })}{" "}
                      -{" "}
                      {new Date(data.End_Time).toLocaleTimeString("fi", {
                        hour: "2-digit",
                        minute: "2-digit",
                        timeZone: "GMT",
                      })}
                    </p>
                    <h4
                      className={classNames(["text-xl", getColor(data.Type)])}
                    >
                      {data.Name}
                    </h4>
                    {!!data.Faculty && (
                      <div>
                        {data.Faculty.map(
                          ({ data: speaker, recordId: speakerId }, indx) => (
                            <Link
                              to={"/faculty#" + speakerId}
                              key={speakerId}
                              className="text-zinc-200 hover:text-red-300"
                            >
                              {indx > 0 && "| "}
                              {speaker.Name}, {speaker.Country}{" "}
                            </Link>
                          )
                        )}
                      </div>
                    )}
                    {!!data.Syllabus && (
                      <div className="mt-2">
                        {data.Syllabus.localFiles.map((syl) => (
                          <a
                            key={syl.id}
                            href={syl.publicURL}
                            target="_blank"
                            className="px-3 py-1 bg-red-800 rounded hover:bg-red-900"
                          >
                            {data.Type == "Lecture"
                              ? "Lecture PDF"
                              : "Programme PDF"}
                          </a>
                        ))}
                      </div>
                    )}
                    {!!data.Comment && (
                      <div className="mt-2">
                        <p>{data.Comment}</p>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Programme;

export const programmePageQuery = graphql`
  query ProgrammePageQuery {
    allAirtable(
      sort: { fields: data___Start_Time }
      filter: { table: { eq: "Programme" } }
    ) {
      group(field: data___Day__from_Sessions_) {
        nodes {
          data {
            Start_Time
            End_Time
            Name
            Session_Number: Number__from_Sessions_
            Session_Title: Title__from_Sessions_
            Type
            Comment
            Faculty {
              data {
                Name
                Institution
                Country
              }
              recordId
            }
            Syllabus {
              localFiles {
                publicURL
                id
              }
            }
            Sessions {
              data {
                Title
                Number
                Faculty {
                  data {
                    Name
                    Country
                    Institution
                  }
                  recordId
                }
              }
            }
            Mentimeter_link
          }
          recordId
        }
        fieldValue
      }
      nodes {
        data {
          Start_Time
          End_Time
          Name
          Session_Number: Number__from_Sessions_
          Session_Title: Title__from_Sessions_
          Type
          Mentimeter_link
          Comment
          Faculty {
            data {
              Name
              Institution
              Country
            }
            recordId
          }
          Syllabus {
            localFiles {
              id
              publicURL
            }
          }
          Sessions {
            data {
              Day
              Title
              Number
            }
            recordId
          }
        }
      }
    }
  }
`;
